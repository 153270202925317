window.addEventListener('DOMContentLoaded', event => {
    // Toggle the side navigation
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            document.body.classList.toggle('sb-sidenav-toggled');
        }
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        });
    }
});
var options = { ajax: { headers: { Authorization: "", Platform: "Web" } } };
if (appConfig.auth_token) {
    options.ajax.headers.Authorization = 'Bearer ' + appConfig.auth_token;
}
// if ($.fn.dataTable.defaults) {
//     $.extend($.fn.DataTable.defaults, options);
// }

$(document).ready(function () {

    setupCommonFunctions();
    addAdditionalMethods();
    addCommonListners();
    setupAjax();
    showHidePassword();

    $("select").on("select2:close", function (e) {
        $(this).valid();
    });

    $("body").on("click", "nav .actions", function (event) {
        if ($('.action-items').has(event.target).length == 0) {
            $(this).toggleClass("activate");
            event.stopPropagation();
        }
    });
    $(".notification-icon>a").click(function () {
        event.stopPropagation();
        $(this).parent(".notification-icon").toggleClass("active");
        if ($("nav .actions").hasClass("activate")) {
            $("nav .actions").removeClass("activate");
        } else {
            notificationList();
        }
    });
    // $(".notification-details").click(function(){
    //     event.stopPropagation();
    // });
    $(document).click(function (e) {
        if ($('nav .actions').has(e.target).length == 0) {
            $("nav .actions").removeClass("activate");
            $(".notification-icon").removeClass("active");
        }
    });


    $(".toggle-menu").on("click", function () {
        $("body").removeClass("nav-open");
        $("body").toggleClass("menu-open");
        $(".menu ul").slideToggle();
    });

    $(".side-toggle").on("click", function () {
        $("body").toggleClass("nav-open");
    });

    //tab-section
    $(".tabs-list li a").click(function (e) {
        e.preventDefault();
    });

    $(".tabs-list li").click(function () {
        var tabid = $(this).find("a").attr("href");
        $(".tabs-list li,.tabs div.tab").removeClass("active"); // removing active class from tab
        $(".tab").hide(); // hiding open tab
        $(tabid).show(); // show tab
        $(this).addClass("active"); //  adding active class to clicked tab
    });

    if ($(".slick").length && $(".table-slider").length == 0) {
        $(".slick").slick({
            slidesToShow: 7,
            infinite: false,
            slidesToScroll: 1,
            autoplaySpeed: 500,
            variableWidth: true,
            arrows: true,
        });
    }

    //tab-section
    $(".tabs-list li a,.slick a").click(function (e) {
        e.preventDefault();
    });

    $(".tabs-list li,.slick .element").click(function () {
        var tabid = $(this).find("a").attr("href");
        $(".tabs-list li,.tabs div.tab,.slick .element").removeClass("active"); // removing active class from tab

        $(".tab").hide(); // hiding open tab
        $(tabid).show(); // show tab
        $(this).addClass("active"); //  adding active class to clicked tab
    });

});
function setupCommonFunctions() {
    window.showToastr = function (text, icon = 'success') {
        toastr.options.closeButton = true;
        toastr[icon](text);
    }
    window.showLoader = function (input) {
        if (input) {
            $(input).block({
                message: '<img src=' + appConfig.loader_image + ' height="30px">',
                baseZ: 9999,
                centerY: 0,
                centerX: 0,
                css: {
                    'top': '0',
                    'left': '0',
                    'right': '0',
                    'bottom': '0',
                    'margin': 'auto',
                    border: 'none',
                    padding: '5px',
                    backgroundColor: '#FFF',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    opacity: 1,
                    color: '#000',
                }
            });
        } else {
            $.blockUI({
                message: '<img src=' + appConfig.loader_image + ' height="85px">',
                baseZ: 9999,
                css: {
                    'top': '0',
                    'left': '0',
                    'right': '0',
                    'bottom': '0',
                    'margin': 'auto',
                    width: '100px',
                    height: '100px',
                    border: 'none',
                    padding: '5px',
                    backgroundColor: '#FFF',
                    '-webkit-border-radius': '10px',
                    '-moz-border-radius': '10px',
                    opacity: 1,
                    color: '#000'
                }
            });
        }

    }
    window.hideLoader = function (input) {
        if (input) {
            $(input).unblock();
        } else {
            $.unblockUI();
        }
    }
    window.calculateAge = function (dob) {

        var todate = new Date();

        var age = [], fromdate = new Date(dob),
            y = [todate.getFullYear(), fromdate.getFullYear()],
            ydiff = y[0] - y[1],
            m = [todate.getMonth(), fromdate.getMonth()],
            mdiff = m[0] - m[1],
            d = [todate.getDate(), fromdate.getDate()],
            ddiff = d[0] - d[1];

        if (mdiff < 0 || (mdiff === 0 && ddiff < 0)) --ydiff;
        if (mdiff < 0) mdiff += 12;
        if (ddiff < 0) {
            fromdate.setMonth(m[1] + 1, 0);
            ddiff = fromdate.getDate() - d[1] + d[0];
            --mdiff;
        }
        if (ydiff > 0) age.push(ydiff + ' Year' + (ydiff > 1 ? 's ' : ''));
        if (mdiff > 0) age.push(mdiff + ' Month' + (mdiff > 1 ? 's' : ''));
        if (mdiff < 1) {
            if (ddiff >= 0) age.push(0 + ' Month');
        }
        if (age.length > 1) age.splice(age.length - 1, 0, ' ');
        return age.join('');

    }
    window.displayAge = function () {
        $('body').find('.display_age').each(function (i, ele) {
            var dob = $(ele).data('dob');
            var age = calculateAge(dob);
            $(ele).html(age);
        });
    }
    window.getErrorFromException = function (jXhr) {
        var errors = jXhr.responseJSON.meta.message;
        if (jXhr.responseJSON.errors) {
            var errors = "";
            //$.each(jXhr.responseJSON.errors, function(key, error){
            $.each(jXhr.responseJSON.errors, function (i, v) {
                errors = errors + v + "<br/>";
            })
            //})
        }
        return errors;
    }
    window.showErrorAlertFromException = function (jXhr) {
        if (jXhr.status == 419) {
            window.location.reload();
            return;
        }
        if (jXhr.status == 401) {
            Cookies.remove('access_token');
            Cookies.remove('uuid');
            window.location.reload();
            return;
        }

        var message = getErrorFromException(jXhr)
        Swal.fire({
            icon: "error",
            html: message
        })
    }
    window.datatableCounter = function (datatable, index) {
        return ((datatable.API.params.pagination.page - 1) * datatable.API.params.pagination.perpage) + (index + 1);
    }
    window.saveAssessmentSuccess = function () {
        Swal.fire({
            icon: "success",
            text: "Assessment saved successfully.",
            confirmButtonText: 'Go To Learner Overview',
            cancelButtonText: 'Continue',
            showCancelButton: true
        }).then(function (result) {
            if (result.isConfirmed) {
                window.location.href = appConfig.url + '/overview/view-details/' + $('body').find('#learner_uuid').val();
            }
        });
    }
    window.exclude = function () {
        return ['total_intervals', 'total_yes_intervals', 'interval_time', 'formatted_date', 'total_sessions', 'highest_rate' , 'highest_frequency', 'time_diff', 'date','mongo_assesment_id', 'question_no', 'selected', 'selected_on', 'mongo_data_collection_id', 'learner_uuid', 'uuid', 'document_uuid', 'frequency', 'paused', 'step_number', 'activity_steps_loked', 'rate', 'duration', 'assesment_inprogress', 'data_collection_inprogress', 'generalized_mastered', 'mastered', 'nextstep_mastered', 'mastery_period', 'percentage', 'prompt_percentage'];
    }
    window.numericKeys = function () {
        return ['x', 'y', 'lineWidth'];
    }
    window.encrypt = function (data) {
        const key = CryptoJS.SHA256(process.env.MIX_SHAKEY).toString(CryptoJS.enc.Hex).substring(0, 32);
        const ivKey = process.env.MIX_IVKEY;
        const encryptedData = CryptoJS.AES.encrypt(data.toString(), CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(ivKey),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return encryptedData.toString();
        //let result = CryptoJS.enc.Utf8.parse(encryptedData.toString());
        //return CryptoJS.enc.Base64.stringify(result);
    }
    window.decrypt = function (data) {
        const key = CryptoJS.SHA256(process.env.MIX_SHAKEY).toString(CryptoJS.enc.Hex).substring(0, 32);
        const ivKey = process.env.MIX_IVKEY;
        const decrypted = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
            iv: CryptoJS.enc.Utf8.parse(ivKey),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
    window.encryptRequestParams = function (params) {
        for (const key in params) {
            const value = params[key];
            if (exclude().includes(key)) {
                continue;
            }
            if (value != "") {
                if (typeof (value) === 'object') {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            encryptRequestParams(value[i]);
                        }
                    } else {
                        encryptRequestParams(value);
                    }
                } else {
                    params[key] = encrypt(value);
                }
            }
        }
        return params;
    }
    window.decryptResponseData = function (data) {
        for (const key in data) {
            const value = data[key];
            if (exclude().includes(key)) {
                continue;
            }
            if (value != "") {
                if (typeof (value) === 'object') {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            decryptResponseData(value[i]);
                        }
                    } else {
                        decryptResponseData(value);
                    }
                } else {
                    data[key] = decrypt(value);
                    if (numericKeys().includes(key)) {
                        if (!data[key]) {
                            data[key] = 0;
                        }
                        data[key] = parseInt(data[key]);
                    }
                }
            }
        }
        return data;
    }
}
function setupAjax() {
    var headers = {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Platform': 'Web'
    };
    if (appConfig.auth_token) {
        headers.Authorization = 'Bearer ' + appConfig.auth_token;
    };
    $.ajaxSetup({
        headers: headers
    });
}
function addCommonListners() {
    $(".btn-refresh").on('click', function () {
        window.location.reload();
    })
    $(".btn-go-back").on('click', function () {
        window.history.back();
    })
    $(".btn-go-home").on('click', function () {
        window.location.href = appConfig.url;
    })
    $(".currency-dropdown").on('click', '.dropdown-item', function () {
        var currency = $(this).data('currency');
        var title = $(this).data('title');
        $dropdownToggle = $(this).closest(".currency-dropdown").find('.dropdown-toggle');
        $dropdownToggle.html(title)
        changeCurrency(currency);
    })
}
function addAdditionalMethods() {
    $.validator.addMethod("pwcheck", function (value, element) {
        var result = /^^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!#$%&()*+,-./:;<=>?@[\]^_`{|}~]{6,}$$/.test(value);
        return result;
    }, 'Password should be minimum of 6 characters and must contain at least one number, one special character, and both uppercase and lowercase letters');

    $.validator.addMethod("timeCompare", function (value, element, opt) {
        var start_time = $.trim($('#start_time input').val()).replace(':', '');
        var end_time = $.trim($('#end_time input').val()).replace(':', '');

        if (parseInt(start_time) != '' && parseInt(end_time) != '') {
            if (parseInt(start_time) > parseInt(end_time)) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    });

    $.validator.addMethod("exportDateCompare", function (value, element, opt) {
        var start_date = $('#exp_start_date').val();
        var end_date = $('#exp_end_date').val();
        if (start_date != '' && end_date != '') {
            if (Date.parse(start_date) > Date.parse(end_date)) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    });
}

function changeCurrency(currency) {
    $.ajax({
        url: appConfig.api_url + "currency/set/" + currency,
        type: "POST",
        beforeSend: function () {
            showLoader();
        },
        success: function () {
            window.location.href = window.location.href;
        }
    })
}


$(function () {
    $(".sidebar-menu-button").click(function () {

        if ($('body').hasClass("sb-sidenav-toggled")) {
            $('body').removeClass("sb-sidenav-toggled");
        } else {
            $('body').addClass("sb-sidenav-toggled");
        }
    });
});

$(document).ready(function () {

    // ------------step-wizard-------------
    //Wizard
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = $(e.target);
        if (target.parent().hasClass('disabled')) {
            return false;
        }
    });

    $(".next-step").click(function (e) {
        // console.log('el', e.target);
        // $(e.target).closest('.tab-pane').fadeOut(2000);
        var active = $('.wizard .nav-tabs li.active');
        active.addClass('completed');
        active.next().removeClass('disabled');
        // console.log(active.next());
        nextTab(active);
    });
    $(".prev-step").click(function (e) {
        var active = $('.wizard .nav-tabs li.active');
        active.removeClass('completed')
        prevTab(active);
    });

    $('.nav-tabs').on('click', 'li', function () {
        // $('.nav-tabs li.active').addClass('completed');
        $('.nav-tabs li.active').removeClass('active');
        // $(this).removeClass('completed');
        $(this).addClass('active');
    });

    // ------------End Step Wizard-------------

    function nextTab(elem) {
        $(elem).next().find('a[data-toggle="tab"]').click();
        // $(elem).next().find('a[data-toggle="tab"]').fadeIn();
    }
    function prevTab(elem) {
        $(elem).prev().find('a[data-toggle="tab"]').click();
        // $(elem).prev().find('a[data-toggle="tab"]').fadeIn();
    }

});

function showHidePassword() {
    $("#password-view").click(function () {
        var input = $('#password');
        if (input.attr("type") == "password") {
            $(this).parent().addClass('visible');
            input.attr("type", "text");
        } else {
            $(this).parent().removeClass('visible');
            input.attr("type", "password");
        }
    });

    if ($('#confirm-password').length > 0) {
        $("#confirm-password-view").click(function () {
            var confirmInput = $('#confirm-password');
            if (confirmInput.attr("type") == "password") {
                $(this).parent().addClass('visible');
                confirmInput.attr("type", "text");
            } else {
                $(this).parent().removeClass('visible');
                confirmInput.attr("type", "password");
            }
        });
    }
}